export class SfCustomerModel {
    CustomerID: number | undefined;
    StoreFrontID: number | undefined;
    Token: string | undefined;
    LoggedIn: boolean | undefined;
    CustomerName: string | undefined;
    CustomerLastName: string | undefined;
    WishListCount: number | undefined;
    KeepMeSignedIn: boolean | undefined;
    RegisteredUser: boolean | undefined;
    AccountPreferences: AccountPreferences | undefined;
    CUSTOMER_SINCE_YEAR: number | undefined;
}
export interface AccountPreferences {
    SelectedTextSize: number | undefined;
    ClientTimeZoneOffSet: number | undefined;
    DeviceFingerPrint: string | undefined;
}

