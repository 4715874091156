import * as React from 'react';
import { SfDeptCategoryStruct } from '../../../SharedContent/SfDeptCategoryStruct'
import { SfCustomerModel } from '../../../SharedContent/SfCustomerModel'
import { Observer } from '../../../SharedContent/Observer'
import { SfConfigModel } from "../../../SharedContent/ServiceClass"
import { IAdjStoreFrontForCountryLanguage, AdjStoreFrontForCountryLanguage } from '../../../SharedContent/IAdjStoreFrontForCountryLanguage'

export class DepartmentsMainComponent extends React.Component<any, any>{

    constructor(props: any) {
        super(props);
        this.state = {
            value: null,
            isLoggedIn: false,
            accountMenuActive: false,
            productsMenuActive: false,
            saleMenuActive: false,
            languageSelectorActive: false,
            sfCatsDrillLevel: 0,
            sfCatsTree: SfDeptCategoryStruct,
            sfCustomer: SfCustomerModel,
            desktopMenuActive: false,
            adjSfCountryLangArr: undefined,
        };
        this.showDepartments = this.showDepartments.bind(this);
        this.MobileSfCatCLICK = this.MobileSfCatCLICK.bind(this);
        this.MobileSfCatBackArrowCLICK = this.MobileSfCatBackArrowCLICK.bind(this);
        this.ToggleMobileAccountMenuCLICK = this.ToggleMobileAccountMenuCLICK.bind(this);
        this.ToggleMobileSaleMenuCLICK = this.ToggleMobileSaleMenuCLICK.bind(this);
        this.showDepartments_External_Notification = this.showDepartments_External_Notification.bind(this);

    }

    private static readonly GetSfCategoriesTree = "webservicesCORE1/topnav/GetSfCategoriesTree";
    private _config: SfConfigModel = new SfConfigModel();
    _adjSfCountryLang: IAdjStoreFrontForCountryLanguage[] | undefined;
    _sfCategoriesTree_IsLoading: boolean = false;
    _sfCategoriesTree_Loaded: boolean = false;
    public sfCatsDrillLevel: number = 0;
    public sfCatsArrayToRender: SfDeptCategoryStruct = new SfDeptCategoryStruct();
    public SfCatsTree: SfDeptCategoryStruct;


    componentDidMount() {
        (window as any).DepartmentsMainComponent = this;
        this.initObserverAndSubscribeToSfCustomerSubject();
        (window as any).$HeaderMiddleware.departmentsMainComponent_FinallyDidMount();

    }

    localObserver: Observer;
    initObserverAndSubscribeToSfCustomerSubject() {
        this.localObserver = new Observer();
        this.localObserver.id = 15;
        this.localObserver.name = ""
        this.localObserver.update = (suppliedContext) => (window as any).DepartmentsMainComponent.receiveNotification_SfCustomer_Departments(suppliedContext)
        this.setState({
            stateObserver: this.localObserver
        },
            function () {

            })
        try {
            if ((window as any).$SfCustomerSubject !== undefined) {
                var customSubj = (window as any).$SfCustomerSubject
                customSubj.addObserver(this.localObserver);
            }
        } catch (exception) {
            console.log(exception);
        }


    }


    receiveNotification_SfCustomer_Departments(context: SfCustomerModel | any) {

        var self = this;
        setTimeout(function () {

            if (context !== undefined && context.LoggedIn && context.CustomerID !== undefined && context.CustomerID > 0) {
                if (self.state.sfCustomer == null ||
                    (!self.state.sfCustomer.LoggedIn || (self.state.sfCustomer.CustomerID !== context.CustomerID || self.state.sfCustomer.WishListCount !== context.WishListCount))) {
                    self.setState({
                        isLoggedIn: true,
                        sfCustomer: context
                    });

                }

            } else {
                self.setState({
                    isLoggedIn: false,
                    sfCustomer: context
                });

            }

        }, 100);

    }



    showDepartments_External_Notification(parentCategoryId: number | undefined) {
        this.showDepartments(parentCategoryId);
        if (!this.state.desktopMenuActive) {
            this.setState({
                desktopMenuActive: true
            });
        }
        this.checkMobileCountryLanguages();
    }


    showDepartments(parentCategoryId: number | undefined) {

        //reset the sfCatsDrill level every time showDepartments is requested
        this.sfCatsDrillLevel = 0;
        if (this.state.sfCatsTree.SubCategories === undefined || this.state.sfCatsTree.SubCategories.length === 0) {
            if (!this._sfCategoriesTree_IsLoading) {
                var promiseJSON = this.getDepartments();
                promiseJSON.then(result =>
                    this.bindResultData(result, parentCategoryId));
            }
        }
        else {
            this.bindResultData(this.state.sfCatsTree.SubCategories, parentCategoryId);
        }


    }

    bindResultData(result: any, parentCategoryId: number | 0 | undefined) {
        if (result !== undefined && result !== null && result.length > 0) {
            this.SfCatsTree = new SfDeptCategoryStruct();
            this.SfCatsTree.SubCategories = result as SfDeptCategoryStruct[];
            this.mobileCategoriesBuildLinkedList(0, this.SfCatsTree, this.SfCatsTree.SubCategories);
            this.mobileCategoriesDrillDown(parentCategoryId);

            

            this.setState({
                sfCatsDrillLevel: this.sfCatsDrillLevel,
                sfCatsTree: this.SfCatsTree,
                sfCatsArrayToRender: this.sfCatsArrayToRender
            },
                function () {

                });

        }
    }


    _checkedMobileCountryLanguages: boolean|undefined;


    checkMobileCountryLanguages(){
        if(!this._checkedMobileCountryLanguages){
            this._checkedMobileCountryLanguages = true;

            try {
                if ((window as any).$AdjSfForSfCountryLanguages_JSON !== undefined) {
                        var adjArr = (window as any).$AdjSfForSfCountryLanguages_JSON as AdjStoreFrontForCountryLanguage[];
                        this._adjSfCountryLang = adjArr;
                        if(adjArr!== undefined && adjArr !== null && adjArr.length >1){
                            this.setState({
                                adjSfCountryLangArr:adjArr
                            })
                        }
                }
    
            } catch (error) {
                //TODO: log error message
            }
        }
    }



    getDepartments() {
        return fetch(this._config.GetBaseUrl() + DepartmentsMainComponent.GetSfCategoriesTree +
            '?storefrontid=' + this._config.get_StoreFrontId() + '&isrestricted=' + this._config.get_IsRestrictedSite(), {
            method: "GET", // *GET, POST, PUT, DELETE, etc.
            mode: "cors", // no-cors, cors, *same-origin
            cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
            credentials: "same-origin", // include, *same-origin, omit
            headers: {
                "Content-Type": "application/json", // "Content-Type": "application/x-www-form-urlencoded",
            }
        })
            .then((response) => response.json())
            .then((result) => {
                return result;
            })
            .catch((error) => {
                console.error(error);
                return error;
            });

    }


    mobileCategoriesDrillDown(parentCategoryId: number | undefined) {
        var sfCat = null;
        var index = 0;
        if (parentCategoryId !== undefined && parentCategoryId > 0) {

            for (var i = 0; i < this.SfCatsTree.SubCategories.length; i++) {
                if (this.SfCatsTree.SubCategories[i].SF_CATEGORY_ID == parentCategoryId) {
                    sfCat = this.SfCatsTree.SubCategories[i];
                    index = i;
                    break;
                }
            }
        }
        if (sfCat !== null) {
            this.MobileSfCatCLICK(sfCat);
        }
        else {
            if (this.sfCatsDrillLevel == 0) {
                if (this.sfCatsArrayToRender == undefined) {
                    this.sfCatsArrayToRender = new SfDeptCategoryStruct();
                }
                this.sfCatsArrayToRender.SubCategories = this.SfCatsTree.SubCategories;
                return;
            }
        }

    }
    mobileCategoriesBuildLinkedList(leafDrillDown: number, parentNode: SfDeptCategoryStruct, subcatsArray: SfDeptCategoryStruct[]) {
        for (let i = 0; i < subcatsArray.length; i++) {
            subcatsArray[i].parentNode = parentNode;
            subcatsArray[i].leafLevelDrillDown = leafDrillDown;
            if (subcatsArray[i].SubCategories !== undefined && subcatsArray[i].SubCategories.length > 0) {
                this.mobileCategoriesBuildLinkedList(leafDrillDown + 1, subcatsArray[i], subcatsArray[i].SubCategories);
            }
        }
    }

    MobileSfCatCLICK(sfCat: SfDeptCategoryStruct) {
        if (sfCat !== undefined && sfCat.SubCategories !== undefined && sfCat.SubCategories.length > 0) {
            if (sfCat.parentNode == undefined) {
                sfCat.parentNode = this.sfCatsArrayToRender.parentNode;
            }
            this.sfCatsArrayToRender.parentNode = sfCat;
            this.sfCatsArrayToRender.SubCategories = sfCat.SubCategories;
            this.sfCatsDrillLevel += 1;
            this.updateTheState();
        }
    }
    MobileSfCatBackArrowCLICK(sfCat: SfDeptCategoryStruct) {

        if (sfCat !== undefined) {
            this.sfCatsArrayToRender.SubCategories = sfCat.parentNode.SubCategories;
            this.sfCatsArrayToRender.parentNode = sfCat.parentNode;
            this.sfCatsDrillLevel -= 1;
            this.updateTheState();
        }
    }
    updateTheState() {
        this.setState({
            sfCatsDrillLevel: this.sfCatsDrillLevel,
            sfCatsArrayToRender: this.sfCatsArrayToRender
        });
    }

    ToggleMobileAccountMenuCLICK() {
        var accountMenuActivePointer = !this.state.accountMenuActive;
        if (accountMenuActivePointer) {
            (window as any).$HeaderMiddleware.MyAccountMiddleware.updateLogin();
        }
        this.setState({
            accountMenuActive: accountMenuActivePointer
        });

    }

    ToggleMobileProductsMenuCLICK() {
        var productsMenuActivePointer = !this.state.productsMenuActive;
        this.setState({
            productsMenuActive: productsMenuActivePointer
        });
    }

    ToggleMobileSaleMenuCLICK() {
        var saleMenuActivePointer = !this.state.saleMenuActive;
        this.setState({
            saleMenuActive: saleMenuActivePointer
        });
    }

    logout_CLICK() {
        (window as any).$HeaderMiddleware.MyAccountMiddleware.logout();
        this.setState({
            isLoggedIn: false,
            sfCustomer: new SfCustomerModel()
        });
    }

    toggleMobileLanguageSelectorMenuCLICK() {
        this.setState({
            languageSelectorActive: !this.state.languageSelectorActive
        });
    }

    mobileLanguageSelector_Item_CLICK(e:React.MouseEvent, storeFrontUrlSubpath:string) {
        e.preventDefault();
        (window as any).$HeaderMiddleware.LanguageSelector.OnChange(storeFrontUrlSubpath);
    }



    render() {
        if (this.state.accountMenuActive) {
            return this.myAccountContents();
        }

        if (this.state.productsMenuActive) {
            return this.productsContent();
        }

        if (this.state.saleMenuActive) {
            return this.saleContent();
        }

        if (this.state.languageSelectorActive) {
            return this.languageSelectorContents(this.state.adjSfCountryLangArr);
        }


        if (!this.state.accountMenuActive && this.state.sfCatsDrillLevel < 1 && this.state.desktopMenuActive) {

            if (this._adjSfCountryLang !== undefined) {
                var buffer = new Array(3);
                buffer[2] = this.getMobileLanguageSelector();
            } else {
                var buffer = new Array(2);

            }

            if (this._config.get_StoreFrontId() !== 519) {
                buffer[0] = this.getMainDepartmentContents();
                buffer[1] = this.getExtendedMobileContent();
            } else {
                buffer[0] = this.getMainDepartmentContents();
                buffer[1] = this.getExtendedOragentMobileContent();
            }            


            return buffer;
            // Need to load this if multi language support available
            //buffer[2] = this.getLangaugeSelector();
            //return this.getBuffer();

        } else {
            if (this.state.sfCatsDrillLevel > 0) {
                return this.getMainDepartmentContents();
            } else {
                return this.getEmptyContnet();
            }

        }

    }


    getEmptyContnet() {
        return <></>;
    }



    getMainDepartmentContents() {
        return this.mainDepartmentContents();
    }

    private mainDepartmentContents() {
        return <div className="nav-mobile">
            {(this.state.sfCatsDrillLevel < 1 &&
                <ul className="menu menu--vertical mobile-nav mobile-main-menu">
                    <li>
                        <a href={this._config.GetBaseUrl()}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 8 8">
                                <title></title>
                                <path d="M4 0l-4 3h1v4h2v-2h2v2h2v-4.03l1 .03-4-3z" />
                            </svg> {(window as any).$StringResource__Mobile_MobiMaster_Home}
                        </a>
                    </li>
                    <li>
                        <a className="mobile-nav__parent mobile-nav__account" onClick={() => this.ToggleMobileAccountMenuCLICK()}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 8 8" aria-label="">
    f                            <title></title>
                                <path d="M4 0c-1.1 0-2 1.12-2 2.5s.9 2.5 2 2.5 2-1.12 2-2.5-.9-2.5-2-2.5zm-2.09 5c-1.06.05-1.91.92-1.91 2v1h8v-1c0-1.08-.84-1.95-1.91-2-.54.61-1.28 1-2.09 1-.81 0-1.55-.39-2.09-1z" />
                            </svg> {(window as any).$StringResource__HeaderNew_txtAccount}
                        </a>
                    </li>
                </ul>
            )}

            {(this.state.sfCatsDrillLevel < 1 && this._config.get_StoreFrontId() === 519 &&
            <ul className="menu menu--vertical mobile-nav mobile-main-menu">
                <li>
                    <a className="mobile-nav__parent mobile-nav__account" onClick={() => this.ToggleMobileProductsMenuCLICK()}>Products</a>
                </li>      
            </ul>
            )}


            {(this.state.sfCatsArrayToRender && this.state.sfCatsArrayToRender.SubCategories && this.state.sfCatsArrayToRender.SubCategories.length > 0 &&
                this.getSfCategories()
            )}

            {/* {this.getCustomerGreetingContents()}
            {this.getDealsOfTheWeek()} */}
        </div>
    }
    private getSfCategories() {
        return <ul className="menu menu--vertical mobile-nav mobile-departments-menu">
            {(this.state.sfCatsDrillLevel > 0) &&
                <li>
                    <a href="javascript:void(0)"
                        className="mobile-nav__back"
                        onClick={() => this.MobileSfCatBackArrowCLICK(this.state.sfCatsArrayToRender.parentNode)} >
                        {(window as any).$StringResource__HeaderNew_txtBack}
                    </a>
                </li>
            }
            {(this.state.sfCatsDrillLevel > 0) &&
                <li>
                    <a href={'productlist/categorydispatch?CID=' + this.state.sfCatsArrayToRender.parentNode.SF_CATEGORY_ID}>
                        {(window as any).stringResourcesDeptMenu.ViewAll} {this.state.sfCatsArrayToRender.parentNode.CategoryName}
                    </a>
                </li>
            }

            {this.getSfCatsToRenderContent(this.state.sfCatsArrayToRender.SubCategories)}


        </ul>
    }

    private getSfCatsToRenderContent(subCategories: SfDeptCategoryStruct[]) {
        var buffer = new Array(subCategories.length)
        for (var i = 0; i < subCategories.length; i++) {
            buffer[i] = this.getSfCatContent(subCategories[i]);
        }

        return buffer;
    }

    private getSfCatContent(sfCat: SfDeptCategoryStruct) {
        var content;
        if (sfCat.SubCategories !== undefined && sfCat.SubCategories.length > 0) {
            content = <li key={sfCat.SF_CATEGORY_ID}>
                <a href="javascript:void(0)"
                    onClick={() => this.MobileSfCatCLICK(sfCat)}
                    className="mobile-nav__parent">
                    {sfCat.CategoryName}
                </a>
            </li>
        } else {
            content = <li key={sfCat.SF_CATEGORY_ID}>
                <a href={'productlist/categorydispatch?CID=' + sfCat.SF_CATEGORY_ID}>
                    {sfCat.CategoryName}
                </a>
            </li>
        }
        return content;
    }
    private getExtendedMobileContent() {
        return <ul className="menu menu--vertical mobile-nav mobile-main-menu mobile-nav--hcb" >
            <li className="menu__item">
                <a href="productList/newproducts">{(window as any).$StringResource__HeaderNew_txtNew}</a>
            </li>
            <li className="menu__item">
                <a href="javascript:void(0)"
                    onClick={() => this.ToggleMobileSaleMenuCLICK()}
                    className="mobile-nav__parent">
                    {(window as any).$StringResource__on_sale_txtDeals}
                </a>
            </li>
            <li className="menu__item">
                <a href="c/lists/healthconcerns">{(window as any).$StringResource__BreadCrumb_HealthConcern}</a>
            </li>
            <li className="menu__item">
                <a href="c/lists/brands">{(window as any).$StringResource__Header_lbl_BrowsePanel_Brands}</a>
            </li>
            <li className="menu__item">
                <a href="help-center">{(window as any).$StringResource__Header_lbl_BrowsePanel_Help}</a>
            </li>
        </ul>
    }

    private getExtendedOragentMobileContent() {
        return <ul className="menu menu--vertical mobile-nav mobile-main-menu mobile-nav--hcb" >
            <li className="menu__item">
                <a href="c/about">About</a>
            </li>
            <li className="menu__item">
                <a href="c/press">Press</a>
            </li>
            <li className="menu__item">
                <a href="c/testimonials">Testimonials</a>
            </li>
            <li className="menu__item">
                <a href="c/contact">Contact</a>
            </li>
            <li className="menu__item">
                <a href="help-center">{(window as any).$StringResource__Header_lbl_BrowsePanel_Help}</a>
            </li>
        </ul>
    }


    getMobileLanguageSelector(){
        if(this.state.adjSfCountryLangArr && this.state.adjSfCountryLangArr.length >1){
            return this.getLangaugeSelector(this.state.adjSfCountryLangArr as AdjStoreFrontForCountryLanguage[]);
        }else{
            return this.getEmptyContnet();
        }
    }

    // TODO: Only Show the language selector when there are multiple languages for a storefront
    private getLangaugeSelector(arr: AdjStoreFrontForCountryLanguage[]) {
        return (
            <div className="mobile-ls">
                <button onClick={() => this.toggleMobileLanguageSelectorMenuCLICK()}>
                    {/* <img className="mobile-ls__flag" src="imgs/flagIcons/svg/gr.svg" /> */}

                    {/*  arr[0].LANGUAGE is the current store front default language */}
                {/*  <span className="mobile-ls__language">{arr[0].LANGUAGE}</span>*/}

                    <span className="mobile-ls__language"> {(window as any).$StringResource__HeaderNew_txtChooseLanguage} </span>
                </button>
            </div>
        );
    }

    // TODO: Need to add available languages and the url to the href
    private languageSelectorContents(arr: AdjStoreFrontForCountryLanguage[]) {
        return (
            <ul className="menu menu--vertical mobile-nav">
                <li>
                    <a href="javascript:void(0)"
                        className="mobile-nav__back"
                        onClick={() => this.toggleMobileLanguageSelectorMenuCLICK()}>
                            {(window as any).$StringResource__HeaderNew_txtBack}
                    </a>
                </li>
                <li className="menu__item">
                    <a onClick={e => e.preventDefault()} style={{ cursor: 'default' }}>
                        <img src="webcontent/icons/checkmark--default.svg" alt={(window as any).$StringResource__GlobalResources_txtActive} /> {arr[0].TRANSLATED_LANGUAGE}
                    </a>
                </li>
                {this.getMobileAdjacentStoreFrontsLanguagesContents(arr)}                
            </ul>
        );
    }

    private getMobileAdjacentStoreFrontsLanguagesContents(arr: AdjStoreFrontForCountryLanguage[]){
        var contentsArr = [];
        arr.map((value, index) => {            
            {(index>0) &&
                contentsArr.push(
                <li className="menu__item">
                    <a href="" onClick={(e) => this.mobileLanguageSelector_Item_CLICK(e, value.URL_SUB_PATH)}>{value.TRANSLATED_LANGUAGE}</a>
                </li>
                );
            }
        })
        return contentsArr;
    }    

    private myAccountContents() {
        return <ul className="menu menu--vertical mobile-nav mobile-account-menu" >
            <li>
                <a href="javascript:void(0)"
                    className="mobile-nav__back"
                    onClick={() => this.ToggleMobileAccountMenuCLICK()} >
                    {(window as any).$StringResource__HeaderNew_txtBack}
            </a>
            </li>
            <li className="menu__item"><a href="m/profile">{(window as any).$StringResource__MyAccount_txtMyProfile}</a></li>
            <li className="menu__item"><a href="m/orders">{(window as any).$StringResource__MyAccount_txtMyOrders}</a></li>
            <li className="menu__item"><a href="m/addresses">{(window as any).$StringResource__HeaderNew_DeliveryAddresses}</a></li>
            <li className="menu__item"><a href="m/paymethods">{(window as any).$StringResource__HelpCenter_txtPaymentMethods}</a></li>

            <li className="menu__item">
                <a href="m/wishlist">
                    {(window as any).$StringResource__Mobile2_cart_txtMyWishlist}
                    {(this.state.sfCustomer && this.state.sfCustomer.LoggedIn && this.state.sfCustomer.WishListCount > 0) &&
                        <span >
                            &nbsp; ({this.state.sfCustomer.WishListCount})
                        </span>
                    }

                </a>
            </li>
            <li className="menu__item"><a href="m/autodelivery">{(window as any).stringResourcesMyAccount.ManageAutoDelivery}</a></li>
            <li className="menu__item"><a href="m/credits">{(window as any).$StringResource__BreadCrumb_MyCredits}</a></li>
            <li className="menu__item"><a href="m/reviews">{(window as any).$StringResource__HeaderNew_MyReviews}</a></li>
            <li className="menu__item"><a href="m/referrals">{(window as any).$StringResource__MyAccount_txtMyReferrals}</a></li>
            {(this.state.sfCustomer && this.state.isLoggedIn) &&
                <li className="menu__item" >
                    <a href="javascript:void(0)" onClick={() => this.logout_CLICK()} className="account-dropdown__sign-out" >
                        <span className="account-dropdown__sign-out--link">
                            {(window as any).$StringResource__HeaderNew_txtSignout}
                    </span>
                    </a>
                </li>

            }

        </ul>
    }

    private productsContent() {
        return <ul className="menu menu--vertical mobile-nav mobile-products-menu">
            <li>
                <a href="javascript:void(0)"
                    className="mobile-nav__back"
                    onClick={() => this.ToggleMobileProductsMenuCLICK()} >
                    {(window as any).$StringResource__HeaderNew_txtBack}
                </a>
            </li>
            <li className="menu__item">
                <a href="product/list/oralgen-nupearl-pro-products?CID=24214">
                    ORALGEN NuPearl&reg; Pro Products
                </a>
            </li>
            <li className="menu__item">
                <a href="product/list/oralgen-nupearl-32x-products?CID=24215">
                    ORALGEN NuPearl&reg; 32x Products
                </a>
            </li>
            <li className="menu__item">
                <a href="product/list/activated-charcoal-products?CID=24216">
                    ORALGEN Charcoal Products
                </a>
            </li>
            <li className="menu__item">
                <a href="product/list/oralgen-nupearl-probiotic-toothpaste-products?CID=24216">
                    ORALGEN NuPearl&reg; Probiotic Toothpaste
                </a>
            </li>
            <li className="menu__item">
                <a href="product/list">
                    View All ORALGEN Products
                </a>
            </li>
        </ul>
    }

    private saleContent() {
        return <ul className="menu menu--vertical mobile-nav mobile-account-menu" >
            <li>
                <a href="javascript:void(0)"
                    className="mobile-nav__back"
                    onClick={() => this.ToggleMobileSaleMenuCLICK()} >
                    {(window as any).$StringResource__HeaderNew_txtBack}
                </a>
            </li>
            <li className="menu__item"><a href={(window as any).$StringResource__on_sale_txtSaleItemsLink}>{(window as any).$StringResource__on_sale_txtSaleItems}</a></li>
            <li className="menu__item"><a href={(window as any).$StringResource__on_sale_txtValuePacksLink}>{(window as any).$StringResource__on_sale_txtValuePacks}</a></li>
            <li className="menu__item"><a href={(window as any).$StringResource__on_sale_txtClearanceLink}>{(window as any).$StringResource__on_sale_txtClearance}</a></li>
        </ul>
    }

    getContents() {
        let contents = this.state.detpDeals !== null ? <></> : <></>;
        //CustomerMainComponent.renderDealsOfTheWeekContents(this.state.detpDeals) : <></>;
        return contents;
    }

}
