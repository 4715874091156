export class SfCategory {
    SF_CATEGORY_ID: number;
    CategoryName: string | undefined;
    PARENT_ID: number | undefined;
    STATUS_RESTRICTED: boolean | undefined;
    SortOrder: number | undefined;
    SubCategories: SfCategory[] | undefined;
    CONFIRMED_ACTIVE: boolean | undefined;
}
export class SfDeptCategoryStruct {

    //constructor(sfCategoryId: number, catName: string, parentId) {

    //}

    parentNode: SfDeptCategoryStruct;
    leafLevelDrillDown: number = 0;
    SF_CATEGORY_ID: number = 22554;
    CategoryName: string = 'Biovea';
    PARENT_ID: number = 0;

    STATUS_RESTRICTED: boolean | undefined;
    SortOrder: number | undefined;
    DeptID: number | undefined;;
    SubCategories: SfDeptCategoryStruct[] | undefined;
    //DeptBrands: SfDeptBrand[] | undefined;
}

export class SfCatSelectedTree {
    nodeParent: SfDeptCategoryStruct | undefined;
    sfCatsArrayToRender: SfDeptCategoryStruct[] | undefined;
    sfCatsDrillLevel: number = 0;
    sfCatsSelectedParentId: number = -1;

}